<template>
  <section class="stickers section">
    <div class="container">
      <div class="stickers__row">
        <div class="stickers-images">
          <img
            v-for="i in 3"
            :key="i"
            :src="require(`../assets/images/stickers/sticker-${i}.png`)"
            alt=""
            class="stickers-images__img"
          />
          <div class="stickers-bg-elements">
            <div class="stickers-bg-elements__item">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.6015 0.776241L22.9219 11.898L31.7189 19.4741L20.5973 22.7923L13.0224 31.5848L9.70186 20.463L0.907796 12.8893L12.0294 9.57116L19.6015 0.776241Z"
                  fill="#71C1DF"
                />
              </svg>
            </div>
            <div class="stickers-bg-elements__item _no-animate">
              <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.3131 23.8526C41.8136 29.1704 36.4076 33.7889 30.7669 37.4401C25.1231 41.0916 19.2417 43.776 15.7819 45.2171C15.6049 45.2903 15.4156 45.2939 15.2444 45.244C15.0702 45.1943 14.9141 45.0911 14.8028 44.9359C10.5379 38.8517 -0.793008 23.8251 0.757906 12.199C1.13881 9.33368 2.14124 6.93708 3.73596 5.08066L3.78619 5.02259C6.92766 1.29752 10.8221 -0.273477 15.9293 0.567059C20.5678 1.32875 25.7203 4.85255 26.6242 10.9791C30.7303 6.41389 34.2208 6.17797 38.6117 7.67382C41.4696 8.64433 45.0409 11.4553 45.6893 16.1978L45.6922 16.2561C46.1151 19.2127 45.1775 22.0152 44.3131 23.8526Z"
                  fill="#D00015"
                />
              </svg>
            </div>
            <div class="stickers-bg-elements__item">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
                  fill="#D20C1C"
                />
              </svg>
            </div>
            <div class="stickers-bg-elements__item">
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M0.522917 3.80148C1.29369 1.14865 4.06801 -0.374685 6.71795 0.396022C9.36999 1.16734 10.8958 3.94133 10.1251 6.59415C9.35428 9.24698 6.5799 10.7702 3.92787 9.99891C1.27792 9.2282 -0.247852 6.4543 0.522917 3.80148Z"
                  fill="#DA0D22"
                />
              </svg>
            </div>
            <div class="stickers-bg-elements__item">
              <svg
                width="60"
                height="61"
                viewBox="0 0 60 61"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39.1818 0.898352L43.8528 23.2654L59.855 39.5819L37.4877 44.2534L21.1711 60.2507L16.4997 37.8835L0.502963 21.5723L22.8703 16.9007L39.1818 0.898352Z"
                  fill="#EFA800"
                />
              </svg>
            </div>
            <div class="stickers-bg-elements__item">
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.4778 0.875019L22.9336 12.6345L31.3468 21.2129L19.5872 23.669L11.0087 32.0796L8.55272 20.32L0.142418 11.7444L11.902 9.28827L20.4778 0.875019Z"
                  fill="#EFA800"
                />
              </svg>
            </div>
            <div class="stickers-bg-elements__item">
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M0.854965 3.9797C1.62572 1.32687 4.40004 -0.196455 7.04998 0.574261C9.70201 1.34559 11.2278 4.11958 10.4571 6.77241C9.68633 9.42524 6.91196 10.9485 4.25992 10.1772C1.60998 9.40644 0.0842047 6.63253 0.854965 3.9797Z"
                  fill="#4FB2D7"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="stickers__content">
          <div class="stickers__title section-title">
            Выразите чувства <br />в Telegram
          </div>
          <div class="stickers__text">
            Скачайте стикерпак и&nbsp;поддерживайте любимых добрыми словами
          </div>

          <a
            v-if="user"
            class="btn btn--primary btn--lg"
            href="https://t.me/addstickers/Kinder_Emotions"
          >
            Открыть в Telegram
          </a>
          <router-link
            v-else
            class="btn btn--primary btn--lg"
            :to="{ name: 'Login' }"
          >
            <span @click="gtmEvent('', 'main', 'open_tg', '')"
              >Открыть в Telegram</span
            >
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.stickers {
  padding-top: rem(110px);
  padding-bottom: rem(30px);
  @media (orientation: landscape) {
    // padding-top: 0;
    padding-bottom: rem(100px);
  }
  &__row {
    display: flex;
    flex-direction: column;
    @media (orientation: landscape) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__title {
    margin-bottom: rem(20px);
    text-align: left;
    @media (max-width: $md) {
      line-height: rem(38px);
    }
  }
  &__text {
    margin-bottom: rem(23px);
    font-family: $font-reg;
    font-size: rem(16px);
    line-height: rem(19px);
    @media (orientation: landscape) {
      margin-bottom: rem(30px);
      font-size: rem(24px);
      line-height: rem(29px);
    }
  }
  &__content {
    width: 85%;
    margin-bottom: rem(30px);
    @media (orientation: landscape) {
      width: rem(421px);
      margin-top: rem(-30px);
      margin-bottom: 0;
    }
  }
  &-images {
    position: relative;
    width: 100%;
    height: rem(260px);
    order: 1;
    @media (orientation: landscape) {
      width: rem(618px);
      height: rem(480px);
      order: 0;
    }
    $trs: 0.3;
    &__img {
      position: absolute;
      // transform: scale(0);
      .active & {
        animation: fadeScale 1s cubic-bezier(0.74, 0.01, 0.28, 1.365) forwards;
      }
      &:nth-child(1) {
        @include size(rem(103px));
        top: rem(60px);
        left: rem(0px);
        @media (orientation: landscape) {
          @include size(rem(225px));
          top: rem(115px);
          left: rem(0px);
        }
      }
      &:nth-child(2) {
        @include size(rem(160px), auto);
        top: rem(79px);
        right: rem(80px);
        animation-delay: #{$trs + "s"};
        @media (orientation: landscape) {
          @include size(rem(350px), auto);
          top: rem(150px);
          right: rem(65px);
        }
      }
      &:nth-child(3) {
        @include size(rem(106px));
        top: rem(5px);
        right: rem(20px);
        animation-delay: #{calc($trs * 2) + "s"};
        @media (orientation: landscape) {
          @include size(rem(227px));
          top: rem(5px);
          right: rem(-50px);
        }
      }
    }
  }
  &-bg-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
    &__item {
      position: absolute;
      display: block;
      transform: scale(0.7);
      animation: rotareStar 4s linear infinite forwards normal;
      svg {
        @include size(100%);
        animation: blinkSmooth 2s infinite forwards alternate;
      }
      &._no-animate {
        animation: none;
      }
      &:nth-child(1) {
        top: 16%;
        left: 38%;
        @media (orientation: landscape) {
          @include size(rem(53px));
        }
      }
      &:nth-child(2) {
        top: 95%;
        left: 92%;
      }
      &:nth-child(3) {
        top: 91%;
        left: 1%;
      }
      &:nth-child(4) {
        top: 23%;
        left: 61%;
      }
      &:nth-child(5) {
        @include size(rem(43px));
        top: 84%;
        left: 20%;
        @media (orientation: landscape) {
          @include size(rem(53px));
          left: 19%;
        }
      }
      &:nth-child(6) {
        top: 60%;
        left: 97%;
      }
      &:nth-child(7) {
        top: 80%;
        left: 98%;
      }
      &:nth-child(2n) {
        animation-delay: 0.5s;
        svg {
          animation-delay: 0.5s;
        }
      }
      &:nth-child(3n) {
        animation-delay: 0.7s;
        svg {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(4n) {
        animation-delay: 1s;
        svg {
          animation-delay: 1s;
        }
      }
    }
  }
}
</style>
