<template>
  <div class="hero section noscroll">
    <div class="container">
      <div class="hero__row">
        <div class="hero__content">
          <router-link
            :to="{ name: 'Home', hash: '#all-stories' }"
            class="btn btn--primary btn--lg"
            >Читать истории</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {
    // this.toCheckUpload()
  },
};
</script>
<style lang="scss" scoped>
.hero {
  position: relative;
  height: calc(var(--vh, 1vh) * 105);
  // padding-bottom: rem(100px);
  z-index: 9;
  display: flex;
  align-items: flex-start;
  background: url("../assets/images/hero/hero-m.jpg") repeat center top;
  background-size: 100%;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(80px);
    background: url("../assets/images/waves.png") repeat-x center;
    background-size: auto 100%;
  }
  .container {
    position: relative;
    padding-bottom: rem(45px);
    height: 100%;
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
  &__row {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: auto;
    flex-direction: column;
  }
  &__col {
    position: relative;
  }

  .btn {
    order: 1;
    width: auto;
    margin-top: auto;
  }

  @media (orientation: portrait) {
    height: 100svh;
    background-size: cover;
  }

  @media (orientation: landscape) {
    height: calc(var(--vh, 1vh) * 100);
    background: url("../assets/images/hero/hero.jpg") no-repeat center top;
    background-size: cover;
    padding-bottom: 0;
    &::before {
      height: rem(110px);
      background-size: 100% 100%;
    }
    .container {
      padding-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
  @media (orientation: landscape) {
    .container {
      padding-top: vh(180px);
      height: 100%;
      align-items: flex-start;
    }
    &__row {
      max-width: 100%;
      flex-direction: row;
      align-items: flex-end;
    }
    .btn {
      width: rem(205px);
      margin-bottom: rem(115px);
    }
  }
  @media (max-width: $xlg) {
  }
}
</style>
