<template>
  <modal
    :name="name"
    height="auto"
    :width="width"
    :adaptive="true"
    :scrollable="true"
    :classes="name === 'video' ? 'modal-video-wrapper' : ''"
    @before-open="beforeOpen"
  >
    <div
      class="modal"
      :class="[
        'modal-' + name,
        size ? 'modal--' + size : '',
        success ? 'modal-success' : '',
      ]"
    >
      <div class="modal__close" @click="closeModal(name)"></div>
      <div class="modal__header" v-if="title">
        <img
          v-if="successIcon"
          src="../assets/images/icons/success.svg"
          alt=""
          class="modal__success-icon"
        />
        <h2 class="modal__title _centered" v-html="title"></h2>
      </div>
      <slot name="content"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    width: {
      default: "578",
    },
    size: {
      type: String,
    },
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    success: {
      type: Boolean,
    },
    successIcon: {
      type: Boolean,
    },
  },
  methods: {
    closeModal(name) {
      this.$emit("closeModal", name);
    },
    beforeOpen(event) {
      this.$parent.beforeOpen(event);
    },
  },
  mounted() {
    // this.$modal.show("success_modal");
  },
};
</script>

<style lang="scss">
.vm--container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  overflow: auto;
  z-index: 99999999 !important;
  .vm--overlay {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(6px);
  }
  &.scrollable {
    overflow: hidden !important;
  }
}
.vm--modal {
  // top: 0 !important;
  // left: 0 !important;
  left: auto !important;
  height: auto !important;
  min-height: 100vh;
  justify-content: flex-end;
  // overflow: visible !important;
  display: flex;
  align-items: flex-start;
  box-shadow: none !important;
  background-color: transparent !important;

  width: auto !important;
  &.modal-video-wrapper {
    width: 100vw !important;
    right: auto;
    justify-self: center;
    .modal-video {
      width: 100%;
      height: 100vh;
      border-radius: 0px !important;
      background: none;
      padding: 0;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: rem(440px);
  max-width: 100%;
  min-height: 100vh;
  padding: rem(60px) rem(20px) rem(20px);
  background: #fff;
  &-check_photo {
    min-height: auto;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: rem(6px);
    right: rem(6px);
    width: rem(48px);
    height: rem(48px);
    background-image: url("../assets/images/icons/close.svg");
    background-repeat: no-repeat;
    background-size: rem(23px);
    background-position: center;
    transition: 0.4s;
    .modal-video & {
      background-image: url("../assets/images/icons/close-white.svg");
      z-index: 100;
    }
  }
  &__success-icon {
    max-height: rem(64px);
    margin: 0 auto rem(24px);
    width: auto;
    object-fit: contain;
  }
  &__title {
    margin-bottom: rem(20px);
    font-size: rem(30px);
    line-height: rem(30px);
    text-align: left;
    & + .modal__text {
      margin-top: rem(-7px);
    }
  }
  &__subtitle {
    margin-bottom: rem(27px);
    font-size: rem(16px);
    color: $brown;
    text-transform: uppercase;
    letter-spacing: -0.01em;
    text-align: center;
  }
  &__text {
    margin-top: rem(0px);
    margin-bottom: rem(20px);
    text-align: center;
    font-size: rem(14px);
    line-height: rem(16px);
    font-family: $font-light;
    opacity: 0.7;
    strong {
      display: block;
      margin-top: rem(10px);
      color: #fff;
      font-size: rem(18px);
      line-height: rem(20px);
    }
  }
  &-signup {
    width: rem(758px);
  }
  &__another-option {
    margin: rem(24px) 0 0;
    font-size: rem(14px);
    line-height: rem(17px);
    text-transform: uppercase;
    color: $brown;
    text-align: center;
    a {
      color: rgba(32, 0, 130, 1);
      border-bottom: 1px solid currentColor;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__list {
    width: 100%;
    margin: auto;
    &-item {
      position: relative;
      padding-left: rem(33px);
      margin-bottom: rem(20px);
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: rem(8px);
        width: rem(8px);
        height: rem(8px);
        border-radius: 50%;
        background: $yellow;
      }
    }
  }
  &-success {
    &__title {
      position: relative;
      padding-top: rem(76px);
      margin-bottom: rem(5px) !important;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: rem(60px);
        height: rem(60px);
        transform: translateX(-50%);
        background: url("../assets/images/success.svg") no-repeat center;
      }
    }
  }
  .success_modal {
    &__text {
      margin-top: 0;
    }
    &-back {
      font-size: rem(12px);
      color: #afafaf;
      text-align: center;
    }
    &-image {
      margin: auto;
    }
    &__footer {
      .btn {
        &:only-child {
          margin: auto;
        }
      }
    }
  }
  &-video {
    padding: 0 !important;
  }
  &__iframe {
    position: relative;
    width: 100%;
    height: 100dvh;
    overflow: hidden;
    iframe,
    video {
      width: 100%;
      height: 100%;
    }
    &-nav {
      position: absolute;
      bottom: 0;
      width: 100%;
      gap: rem(20px);
      padding: rem(26px) 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.4s;
      &.hide {
        opacity: 0;
      }
      &:hover {
        opacity: 1;
      }
      &-play {
        width: rem(117px);
        cursor: pointer;
      }
      &-prev,
      &-next {
        display: flex;
        align-items: center;
        height: rem(70px);
        padding: rem(15px);
        border-radius: rem(60px);
        background: #fff;
        font-size: rem(20px);
        font-family: $font-reg;
        cursor: pointer;
        img {
          width: rem(40px);
        }
        &.hide {
          opacity: 0;
          pointer-events: none;
        }
      }
      &-prev {
        padding-right: rem(24px);
        img {
          margin-right: rem(10px);
        }
      }
      &-next {
        padding-left: rem(24px);
        img {
          margin-left: rem(10px);
        }
      }
    }
  }
  @media (max-width: $md) and (orientation: portrait) {
    &__iframe {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        background: url("../assets/images/rotate.jpg") no-repeat center top;
        background-size: cover;
        z-index: 1;
      }
    }
  }
  @media (orientation: landscape) {
    min-height: auto;
    justify-content: flex-start;
    padding: rem(50px) rem(85px) rem(60px);
    border-radius: rem(35px) 0 0 rem(35px);
    &--md {
      width: rem(520px);
      padding: rem(30px) rem(50px) rem(40px);
    }
    &--lg {
      width: 45vw;
      min-height: 100vh;
      padding: rem(70px) rem(40px) rem(70px) rem(91px);
      &.modal-image {
        width: 50vw;
        padding: rem(50px) 0;
        img {
          width: 100%;
        }
      }
    }
    &__title {
      margin-bottom: vh(20px);
      font-size: vh(48px);
      line-height: 1.1;
      .modal-feedback & {
        text-align: left;
      }
    }
    &__success-icon {
      max-height: rem(90px);
      margin: 0 auto rem(30px);
      width: auto;
      object-fit: contain;
    }
    &__text {
      font-size: rem(12px);
      line-height: rem(15px);
      strong {
        display: block;
        margin-top: rem(12px);
        font-size: rem(26px);
        line-height: rem(30px);
      }
    }
    &__list {
      width: 70%;
    }
    &__subtitle {
      margin-bottom: rem(30px);
      font-size: rem(24px);
      line-height: rem(25px);
    }
    &-success,
    &.modal-common_error {
      justify-content: center;
    }
    &__another-option {
      margin: rem(30px) auto 0;
      font-size: rem(22px);
    }
  }
}
</style>
