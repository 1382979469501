<template>
  <section class="all-stories section" id="all-stories">
    <div class="container _small-width">
      <div class="section-title">Все истории</div>
      <VueSlickCarousel
        ref="slider"
        @beforeChange="beforeChange"
        v-bind="settings"
        class="all-stories-list"
      >
        <div
          v-for="(item, i) in stories"
          :key="i"
          class="all-stories-list__item"
        >
          <div class="all-stories-list__item-card" :class="item.className">
            <div class="all-stories-list__item-title">
              <span v-html="item.title"></span>
              <div class="all-stories-list__item-index">{{ i + 1 }}</div>
            </div>
            <div
              class="all-stories-list__item-img-wrapper"
              @click="pushToStories(i)"
            >
              <img
                :src="require(`../assets/images/stories/${item.img}`)"
                alt=""
                class="all-stories-list__item-img"
              />
            </div>
            <div
              v-if="
                $store.getters.user &&
                $store.getters.user.stories.includes(i + 1)
              "
              :key="storiesIndex"
              class="all-stories-list__item-status"
            >
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5477 2.04675L5.64722 11.2576L1.60156 7.07083"
                  stroke="#52245D"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>прочитана</span>
            </div>
            <button
              v-else
              :key="storiesIndex + 1"
              @click="pushToStories(i)"
              class="btn btn--bordered"
            >
              Читать историю
            </button>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  data: () => ({
    storiesIndex: 1,
    storiesCount: null,
    settings: {
      arrows: true,
      dots: false,
      slidesToShow: 3,
      centerMode: true,
      centerPadding: "0px",
      infinite: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "6%",
            slidesToScroll: 1,
            fade: false,
            dots: false,
          },
        },
      ],
    },
    stories: [
      {
        img: "img-1.png",
        title: "Я горжусь тобой",
        className: "_yellow-bg",
        status: "done",
      },
      {
        img: "img-2.png",
        title: "Я тобой восхищаюсь",
        className: "_blue-bg",
        status: "undone",
      },
      {
        img: "img-3.png",
        title: "Я в тебя верю",
        className: "_orange-bg",
      },
      {
        img: "img-4.png",
        title: "Я тобой дорожу",
        className: "_yellow-bg",
      },
      {
        img: "img-5.png",
        title: "Я тебя обожаю",
        className: "_blue-bg",
      },
      {
        img: "img-6.png",
        title: "Я тебя поддерживаю",
        className: "_orange-bg",
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    beforeChange(oldSlideIndex,newSlideIndex) {
      if (oldSlideIndex > newSlideIndex) {
        this.gtmEvent('', 'stories', 'slider_prev');
      } else {
        this.gtmEvent('', 'stories', 'slider_next');
      }
    },
    pushToStories(i) {
      let item = i + 1;
      this.$modal.show("video", { count: item });
      this.storiesCount = localStorage.getItem("stories");
      this.gtmEvent('', 'stories', 'story_click', item);        this.storiesIndex++;
      
    },
  },
  mounted() {
    this.storiesCount = localStorage.getItem("stories") || "";
    this.$store.commit("SET_STORIES_COUNT", this.storiesCount);
  },
};
</script>

<style lang="scss">
.all-stories {
  padding-top: rem(65px);
  @media (orientation: landscape) {
    padding-top: rem(73px);
  }
  &-list {
    width: calc(100% + rem(40px));
    margin-left: rem(-20px);
    padding-top: rem(13px);
    @media (min-width: $md) and (orientation: landscape) {
      width: 100%;
      margin-left: 0;
      padding-top: rem(5px);
      height: rem(466px);
    }
    &__item {
      padding: 0 rem(6px);
      @media (orientation: landscape) {
        padding: 0 rem(10px);
      }
      &-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: rem(430px);
        padding: rem(38px) rem(30px);
        border-radius: rem(35px);
        transition: 0.4s;
        .slick-current & {
          height: rem(466px);
        }
        &._yellow-bg {
          background: #fdde77;
        }
        &._blue-bg {
          background: #45c6e6;
        }
        &._orange-bg {
          background: #f9c5a6;
        }
      }
      &-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: rem(0px);
        font-size: rem(28px);
        line-height: 0.9;
        @media (orientation: landscape) {
          margin-bottom: rem(23px);
        }
        span {
          width: 70%;
        }
      }
      &-index {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: rem(3px);
        @include size(rem(40px));
        border-radius: 50%;
        font-size: rem(16px);
        line-height: 1;
        background: rgba(#ffffff, 0.5);
      }
      &-img {
        @include size(100%);
        object-fit: cover;
        &-wrapper {
          @include size(rem(224px));
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
        }
      }
      &-status {
        display: flex;
        align-items: center;
        margin-top: rem(22px);
        height: rem(50px);
        svg {
          margin-right: rem(7px);
          margin-left: rem(-19px);
        }
      }
      .btn {
        width: rem(214px);
        margin-top: rem(20px);
      }
    }
  }
}
</style>
